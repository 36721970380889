import axios from "axios";

import router from "@/router";

const API_ROOT = process.env.VUE_APP_API_ROOT;

const api = axios.create({
  baseURL: API_ROOT,
});

// 接口请求前
api.interceptors.request.use(function (request) {
  request.headers.common["application"] = "mq-admin-pc";
  if (router.currentRoute.name === "login") {
    return request;
  }
  // 是否已授权，是否有token
  let token = localStorage.getItem("auth.token");
  if (token) {
    request.headers.common["Authorization"] = `Bearer ${token}`;
    return request;
  }
  return request;
});

// 接口响应后
api.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      router.push({ name: "login" });
    } else if (error.response.status === 403) {
      router.push({ name: "no-permission" });
    } else if (error.response.status === 404) {
      router.push({ name: "not-found" });
    } else if (error.response.status === 500) {
      router.push({ name: "server-error" });
    } else {
      return Promise.reject(error.response.data);
    }
  }
);

export default api;
